import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import planandbilling from 'img/livesessionApp/planbilling.png';
import planandbillingquora from 'img/livesessionApp/planbillingquora.png';
import ex1 from 'img/livesessionApp/quoralimitex1.svg';
import ex2 from 'img/livesessionApp/quoralimitex2.svg';

const Content = () => {
  return (
    <div>
      <SubTitle>How quota limit works</SubTitle>
      <p>
        Every 30 days, you’re assigned a certain number of sessions. For instance, when your plan
        covers 10 000 sessions, you can record 10 000 sessions within 30 days.
      </p>
      <p>
        If you record the maximum number of sessions before the 30-day period ends, you won’t be
        able to record more sessions until the quota limit is renewed. Deleting recordings won’t
        allow you to record more sessions. If you’d like to extend the limit, you can{' '}
        <a href="https://livesession.io/pricing/" target="_blank" rel="noopener noreferrer">
          upgrade
        </a>{' '}
        to a more extended plan.{' '}
      </p>
      <p>
        You can find information about your current quota limit in Settings &gt; Plan & Billing.
      </p>
      <Image
        src={planandbillingquora}
        alt="Plan & Billing - quora limit"
        title="Check your current quora limit"
      />
      <SubTitle>How retention works</SubTitle>
      <p>
        Every pricing plan comes with a different data retention period. This is why every session
        recording is stored together with information about its retention option (1, 3, 6 or 12
        months).
      </p>
      <p>
        Based on this data, the session will be deleted from your LiveSession account after a given
        period.{' '}
      </p>
      <p>
        Changing your retention option doesn’t change the retention period for sessions that were
        already recorded. If you know that you’re going to need a longer retention period, it’s
        better to choose it at the start.
      </p>
      <p>
        You can find more detailed information in the <strong>Settings &gt; Plan & Billing</strong>{' '}
        section.{' '}
      </p>
      <Image
        src={planandbilling}
        alt="Plan & Billing - data retention"
        title="Check your data retention"
      />
      <p>
        <strong>Example 1</strong>: Your retention period is 1 month, your quota limit is 10 000
        sessions and you’ve used it all on the 29th day. On the 30th day, you’ll be assigned another
        10 000 sessions. If you use them all on the 30th day, you’ll have 20 000 session recordings
        on your account. 10 000 of them will be deleted after 29 days, and the other 10 000 – after
        30 days.{' '}
      </p>
      <Image
        src={ex1}
        alt="Plan & Billing - quora limit - example 1"
        title="Quora limit"
        cardStyle={{ boxShadow: 'none', margin: '2rem 0' }}
        imgStyle={{ border: 'none' }}
      />
      <p>
        <strong>Example 2</strong>: If your quota is 10 000 sessions and your retention period is 3
        months, you’re able to record 10 000 sessions every month. By the end of the third month,
        you can have up to 30 000 session recordings on your account. The first sessions will be
        deleted after 3 months.
      </p>
      <Image
        src={ex2}
        alt="Plan & Billing - quora limit - example 2"
        title="Quora limit"
        cardStyle={{ boxShadow: 'none', margin: '2rem 0 0' }}
        imgStyle={{ border: 'none' }}
      />
    </div>
  );
};

const related = [
  {
    link: '/help/what-is-a-session/',
    title: 'What is a session?',
    description: 'A session is a set of the user’s interactions with a website within a timeframe.',
  },
];

export const frontmatter = {
  metaTitle: 'How retention and quota limits work?',
  metaDescription: 'Check how retention and quota limit works',
  canonical: '/help/how-retention-and-quota-limits-work/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
